import * as React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <h2>このページは存在しません</h2>
    <a href="/">TOPにもどる</a>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage